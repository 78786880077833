























































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'
import CharacterCard from '@/components/cards/CharacterCard.vue'
import ControlsRequestFormPart from '@/components/forms/parts/ControlsRequestFormPart.vue'
import QuartersFormPart from '@/components/forms/parts/QuartersFormPart.vue'
import CarFormPart from '@/components/forms/parts/CarFormPart.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'

// INTERFACES
import { AuthorRequest, CreateReviewRequest, UserTypeEnum } from '@/store/types'

// STORE
import OrderModule from '@/store/modules/order'
import FleetOrderModule from '@/store/modules/fleet/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import DriverOrderModule from '@/store/modules/driver/order'

@Component({
  components: {
    DefaultLayout,
    ValidationProvider,
    ValidationObserver,
    CharacterCard,
    ControlsRequestFormPart,
    RequestFormPartWrapper,
    QuartersFormPart,
    CarFormPart,
    CaptionCard,
  },
})
export default class ReviewOrderCreate extends Mixins(SystemMixin, NotifyMixin) {
  private rules = {
    count: (value: string) => value.length <= 1000,
  }

  // FORM
  private form: CreateReviewRequest = {
    author: {} as AuthorRequest,
    comment: '',
    carId: 0,
    serviceTitle: 'Жалоба, вопрос, предложение, благодарность',
  }

  private isShowCar = false

  private get currentMileage () {
    return OrderModule.carSearchResponse.currentMileage
  }

  private created () {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      const payload = {
        entity: +this.entity,
        params: {
          hasRepair: true,
        },
      }

      FleetOrderModule.getOrderDictionaries(payload)
    } else {
      DriverOrderModule.getOrderDictionaries(+this.entity)
    }
  }

  private handleCloseCar () {
    this.isShowCar = false
    this.form.carId = 0
  }

  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.form.author.type = this.role === 'fleet' || this.role === 'mechanic'
            ? 'manager' as UserTypeEnum
            : 'driver' as UserTypeEnum

          this.form.carId = this.form.carId ? this.form.carId : undefined

          const body: any = {
            ...this.form,
          }

          if (this.currentMileage) {
            body.currentMileage = this.currentMileage
          }

          if (this.role === 'fleet' || this.role === 'mechanic') {
            FleetOrderModule.sendReview({ entity: +this.entity, body })
              .then((r) => {
                this.$openModal('OrderSendSuccessModal', 'big', {
                  route: {
                    name: `view-order.${r.type}`,
                    params: { orderId: r.id },
                  },
                })
                this.$router.push({ name: 'orders' })
              })
              .catch(() => {
                this.notifyError('Заявка не отправлена. Необходимо заполнить все обязательные поля.')
              })
          } else {
            DriverOrderModule.sendReview({ entity: +this.entity, body })
              .then((r) => {
                this.$openModal('OrderSendSuccessModal', 'big', {
                  route: {
                    name: `view-order.${r.type}`,
                    params: { orderId: r.id },
                  },
                })
                this.$router.push({ name: 'orders' })
              })
              .catch(() => {
                this.notifyError('Заявка не отправлена. Необходимо заполнить все обязательные поля.')
              })
          }
        }
      })
  }
}
